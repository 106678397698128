<template>
  <!-- 短信模板 -->
  <div class="appletMessageConfiguration-page">
    <el-tabs v-model="activeName" @tab-click="tabClickFun">
      <el-tab-pane label="业务代码" name="business"></el-tab-pane>
      <el-tab-pane label="渠道属性" name="channel"></el-tab-pane>
    </el-tabs>
    <div class="add-btn">
      <el-button
        v-if="listFind('添加业务代码') || listFind('添加渠道属性')"
        type="primary"
        size="small"
        @click="
          openDialogFun(
            activeName === 'business' ? '新增业务代码' : '新增渠道属性'
          )
        "
        >添加</el-button
      >
    </div>
    <div class="table-box">
      <Table
        :table-data="tableData"
        :title-name="titleName"
        :operation="titleName.length === 3"
        col-class-type
        operation-width="200"
      >
        <template slot-scope="scope">
          <div>
            <template>
              <el-button
                v-if="listFind('编辑')"
                type="text"
                size="small"
                sort="primary"
                @click="openDialogFun('编辑', scope.scopeRow)"
                >编辑</el-button
              >
              <el-button
                v-if="listFind('配置')"
                type="text"
                size="small"
                sort="primary"
                @click="openDialogFun('配置', scope.scopeRow)"
                >配置</el-button
              >
            </template>
          </div>
        </template>
      </Table>
    </div>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, prev, pager, next, jumper"
        :total="total"
        :page-size="tableParmas.pageSize"
        :current-page="tableParmas.currentPage"
        @size-change="sizeChangeFun"
        @current-change="currentChangeFun"
      ></el-pagination>
    </div>
    <div class="dialog-box">
      <el-dialog
        v-if="dialogVisible"
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="30%"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <template v-if="activeName === 'business'">
            <el-form-item label="业务代码：" prop="miniProgramBusinessCode">
              <el-input
                v-model.trim="ruleForm.miniProgramBusinessCode"
                placeholder="请输入业务代码"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="业务名称：" prop="miniProgramBusinessName">
              <el-input
                v-model.trim="ruleForm.miniProgramBusinessName"
                placeholder="请输入业务名称"
                clearable
              ></el-input>
            </el-form-item>
          </template>
          <template v-if="activeName === 'channel'">
            <el-form-item label="渠道：" prop="channelNo">
              <el-select
                v-model="channelNo"
                :disabled="dialogTitle === '编辑'"
                value-key="channelNo"
                clearable
                placeholder="请选择渠道"
                @change="changeSelect"
              >
                <el-option
                  v-for="(item, index) in channelList"
                  :key="index"
                  :label="item.channelName"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="AppID：" prop="appId">
              <el-input
                v-model.trim="ruleForm.appId"
                placeholder="请输入AppID"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="AppSecret：" prop="appSecret">
              <el-input
                v-model.trim="ruleForm.appSecret"
                placeholder="请输入AppSecret"
                clearable
              ></el-input>
            </el-form-item>
          </template>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmFun">确 认</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  queryMiniProgramBusinessCodePageAPI, //小程序业务代码配置分页
  saveMiniProgramBusinessCodeAPI,
  queryMiniProgramLicensePageAPI, //小程序渠道配置分页
  saveMiniProgramLicenseAPI,
  updateMiniProgramLicenseAPI,
  queryChannelListAPI,
} from "@/api/lib/api.js";
export default {
  name: "appletMessageConfiguration",
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      dialogTitle: "",
      activeName: "",
      channelNo: {},
      ruleForm: {
        miniProgramBusinessCode: null,
        miniProgramBusinessName: null,
        channelNo: null,
        channelName: null,
        appId: null,
        appSecret: null,
      },
      rules: {
        miniProgramBusinessCode: [
          { required: true, message: "请输入业务代码", trigger: "blur" },
        ],
        miniProgramBusinessName: [
          { required: true, message: "请输入业务名称", trigger: "blur" },
        ],
        channelNo: [
          { required: true, message: "请选择渠道", trigger: "change" },
        ],
        appId: [{ required: true, message: "请输入AppID", trigger: "blur" }],
        appSecret: [
          { required: true, message: "请输入AppSecret", trigger: "blur" },
        ],
      },
      channelList: [], //渠道数据
      total: 0,
      tableParmas: {
        currentPage: 1,
        pageSize: 10,
      },
      tableData: [],
      titleName: [],
    };
  },
  filters: {},
  computed: {},
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.dialogTitle = "";
        this.ruleForm = this.$options.data().ruleForm;
        this.channelNo = {};
        this.channelList = [];
      }
    },
    activeName(val) {
      switch (val) {
        case "business":
          this.titleName = [
            {
              title: "业务代码",
              props: "miniProgramBusinessCode",
            },
            {
              title: "业务名称",
              props: "miniProgramBusinessName",
            },
          ];
          break;
        case "channel":
          this.titleName = [
            {
              title: "渠道",
              props: "channelName",
            },
            {
              title: "AppID",
              props: "appId",
            },
            {
              title: "AppSecret",
              props: "appSecret",
            },
          ];
          break;
        default:
          break;
      }
    },
  },
  created() {
    if (this.$route.params.from === "appletMessageDetail") {
      this.activeName = "channel";
    } else {
      this.activeName = "business";
    }
    this.getTableData();
  },
  mounted() {},
  methods: {
    /**
     * @description 请求列表数据
     */
    getTableData() {
      let path =
        this.activeName === "business"
          ? queryMiniProgramBusinessCodePageAPI
          : queryMiniProgramLicensePageAPI;
      path(this.tableParmas).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data?.list || [];
          this.total = res.data.total;
        }
      });
    },
    // 分页页数发生变化
    currentChangeFun(val) {
      this.tableParmas.currentPage = val;
      this.getTableData();
    },
    // 分页change方法
    sizeChangeFun(val) {
      this.tableParmas.pageSize = val;
      this.getTableData();
    },
    /**
     * @description 打开弹窗
     */
    openDialogFun(title, row) {
      this.dialogTitle = title;
      if (title === "配置") {
        this.$router.push({
          path: "/projectTravel/appletMessageConfiguration/appletMessageDetail",
          query: { channelNo: row.channelNo },
        });
        return;
      } else if (title === "编辑") {
        this.channelNo = {
          channelNo: row.channelNo,
          channelName: row.channelName,
        };
        this.ruleForm = JSON.parse(JSON.stringify(row));
        // querySmsLicenseInfo({channelNo:row.channelNo}).then(res=>{
        //   if(res.code === "SUCCESS"){
        //     this.ruleForm = res.data;
        //   }
        // })
      }
      this.activeName === "channel" && this.getQueryChannelList();
      this.dialogVisible = true;
    },
    /**
     * @description 确认回调
     */
    confirmFun() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.dialogTitle === "编辑") {
            updateMiniProgramLicenseAPI(this.ruleForm).then((res) => {
              if (res.code == "SUCCESS") {
                this.$message.success(res.msg);
                this.dialogVisible = false;
                this.getTableData();
              }
            });
          } else {
            let savePath =
              this.activeName === "business"
                ? saveMiniProgramBusinessCodeAPI
                : saveMiniProgramLicenseAPI;
            savePath(this.ruleForm).then((res) => {
              if (res.code == "SUCCESS") {
                this.$message.success(res.msg);
                this.dialogVisible = false;
                this.getTableData();
              }
            });
          }
        }
      });
    },
    /**
     * @description 获取渠道数据
     */
    getQueryChannelList() {
      queryChannelListAPI({ status: 0 }).then((res) => {
        if (res.code == "SUCCESS") {
          this.channelList = res.data;
        }
      });
    },
    //选择渠道
    changeSelect(val) {
      this.ruleForm.channelName = val.channelName;
      this.ruleForm.channelNo = val.channelNo;
    },
    //切换tab
    tabClickFun() {
      this.dialogTitle = "";
      this.tableData = [];
      this.total = 0;
      this.ruleForm = this.$options.data().ruleForm;
      this.tableParmas = this.$options.data().tableParmas;
      this.getTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
.appletMessageConfiguration-page {
  background: #fff;
  padding: 20px;
  .table-box {
    margin-top: 20px;
  }
  .dialog-box {
    .demo-ruleForm {
      .el-select {
        width: 100%;
      }
    }
  }
}
</style>
